<template>
    <ContentField>
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>A</th>
                    <th>B </th>
                    <th>对战结果</th>
                    <th>对战时间</th>
                    <th>对战回合</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="record in records" :key="record.record.id">
                    <td>
                        <img :src="record.a_photo" alt="" class="record-user-photo">
                        &nbsp;
                        <span class="record-user-username">{{ record.a_username }}</span>
                    </td>
                    <td>
                        <img :src="record.b_photo" alt="" class="record-user-photo">
                        &nbsp;
                        <span class="record-user-username">{{ record.b_username }}</span>
                    </td>
                    <td>
                        {{ record.result }}

                    </td>
                    <td>{{ record.record.createTime }}</td>
                    <td>{{ Math.max(0, record.total_steps - 1) }}</td>
                    <td>
                        <button @click="open_record_content(record.record.id)" type="button"
                            class="btn btn-outline-info">查看录像</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="row">
            <div class="col-2">
                <select class="form-select" @change="update_present">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
            <div class="col-10">
                <nav aria-label="...">
                    <ul class="pagination" style="float: right;">
                        <li class="page-item" @click="click_page(-2)">
                            <a class="page-link">
                                &laquo;
                            </a>
                        </li>
                        <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number"
                            @click="click_page(page.number)">
                            <a class="page-link" href="#">
                                {{ page.number }}
                            </a>
                        </li>

                        <li class="page-item" @click="click_page(-1)">
                            <a class="page-link" href="#">&raquo;</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>


    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
import { useStore } from 'vuex';
import { ref } from 'vue';
import $ from 'jquery'
import router from '@/router';

export default {
    components: {
        ContentField
    },
    setup() {
        const store = useStore();
        let records = ref([]);
        let current_page = 1;
        let current_present = 5;
        let total_records = 0;

        let pages = ref([]);

        const update_present = event => {
            current_present = parseInt(event.target.value);
            console.log(current_present + "  " + event.target.value);
            update_pages();
            pull_page(current_page, current_present);
        }

        const click_page = page => {

            if (page === -2) {
                page = current_page - 1;
            } else if (page === -1) {
                page = current_page + 1;
            }
            let max_pages = parseInt(Math.ceil(total_records / current_present));
            if (page >= 1 && page <= max_pages) {
                pull_page(page, current_present);
            }
        }

        const update_pages = () => {
            let max_pages = parseInt(Math.ceil(total_records / current_present));
            let new_pages = [];
            for (let i = current_page - 2; i <= current_page + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_pages.push({
                        number: i,
                        is_active: i === current_page ? "active" : "",
                    });
                }
            }
            pages.value = new_pages;
        }

        console.log(total_records);
        const pull_page = (page, present) => {
            current_page = page;
            current_present = present;
            $.ajax({
                url: "https://gta5.fun/api/record/getlist/",
                data: {
                    page,
                    present,
                },
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    records.value = resp.records;
                    total_records = resp.records_count;
                    update_pages();
                },
                error(resp) {
                    console.log("error " + resp);
                }
            })
        }
        pull_page(current_page, current_present);

        const stringTo2D = map => {
            let g = [];
            for (let i = 0, k = 0; i < 13; i++) {
                let line = [];
                for (let j = 0; j < 14; j++, k++) {
                    if (map[k] === '0') {
                        line.push(0);
                    } else {
                        line.push(1);
                    }
                }

                g.push(line);
            }
            return g;
        }

        const open_record_content = recordId => {
            for (const record of records.value) {
                if (record.record.id === recordId) {
                    store.commit("updateIsRecord", true);
                    console.log(record);
                    store.commit("updateGame", {
                        map: stringTo2D(record.record.map),
                        a_id: record.record.aid,
                        a_sx: record.record.asx,
                        a_sy: record.record.asy,
                        b_id: record.record.bid,
                        b_sx: record.record.bsx,
                        b_sy: record.record.bsy,

                    });
                    store.commit("updateSteps", {
                        a_steps: record.record.asteps,
                        b_steps: record.record.bsteps,
                    });
                    store.commit("updateRecordLoser", record.record.loser);
                    router.push({
                        name: "record_content",
                        params: {
                            recordId
                        }
                    })
                    break;
                }
            }
        }

        return {
            records,
            open_record_content,
            pages,
            click_page,
            update_present,
        }
    }
}
</script>

<style scoped>
img.record-user-photo {
    width: 4vh;
    border-radius: 70%;
}
</style>